import { createApp } from 'vue';
import GlossaryPageListView from "./glossary-page-list-view.vue";
import type { GlossaryPageTerms } from './glossary-page-types.js';

export function initializeGlossaryPage(glossary: Array<GlossaryPageTerms>) {

  const app = createApp(GlossaryPageListView, { glossary }); 
  app.mount('#glossary-app');
  app.config.errorHandler = (err) => {
    console.error(err);
  };
}