<script setup lang="ts">
import { ref, watch } from 'vue';

const props = defineProps<{
  numberOfPages: number,
  currentPage: number,
}>();
const emit = defineEmits<{
  (e: 'update:currentPage', data: number): void
}>();
const ActivePage = ref(props.currentPage);
watch(() => props.currentPage, (newPage) => {
  ActivePage.value = newPage;
});
function HandlePageChange(num: number) {
  ActivePage.value = num;
  window.scrollTo(0,0);
  emit('update:currentPage', num);
}
</script>
<template>
  <div class="container pagi">
    <ul class="pagination">
      <li v-for="n in numberOfPages" class="page-item" :class="ActivePage == n ? 'active' : ''"
        @click="HandlePageChange(n)">{{ n }}</li>
    </ul>
  </div>
</template>