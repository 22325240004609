<script lang="ts" setup>
import { ref, computed } from 'vue';
import type { GlossaryPageTerms } from './glossary-page-types.js';
import glossaryCard from './glossary-page-cards.vue';
import breadCrumb from './glossary-page-breadcrumbs.vue';
import pagination from './glossary-page-pagination.vue';
import searchBar from './glossary-page-search.vue';

export interface PropModel {
  glossary: Array<GlossaryPageTerms>,
}
const props = defineProps<PropModel>();
const RawResults = props.glossary;
const CARDS_PER_PAGE = 12;
const CurrentPage = ref(1);
const SearchFilter = ref("");
const FilteredResults = computed((): GlossaryPageTerms[] => {
  if (!RawResults || (RawResults && RawResults.length === 0)) return [];
  if (!SearchFilter.value) return RawResults;
  CurrentPage.value = 1;
  return RawResults.filter(
    term => term.Title.toLowerCase().startsWith(SearchFilter.value));
});
//calculates the range of card that will be shown per page
const CurrentResults = computed(() => {
  const start = (CurrentPage.value - 1) * CARDS_PER_PAGE;
  var end = start + CARDS_PER_PAGE;
  if (FilteredResults.value.length < end) end = FilteredResults.value.length;
  return FilteredResults.value.slice(start, end);
});
//total number of pages based on # of Cards per Page
const TotalNumberOfPages = computed(() => {
  return Math.ceil(FilteredResults.value.length / CARDS_PER_PAGE);
});

</script>
<template>
  <div class="top-content neutral">
    <div class="container">
      <div class="">
        <breadCrumb />
        <h1 class="primary-heading">MasterControl Glossary</h1>
        <h2 class="featured-sub-head">Industry Terms and Definitions</h2>
      </div>
      <searchBar v-model="SearchFilter" />
    </div>
  </div>
  <div class="container term-cards">
    <div class="card-container">
      <glossaryCard v-if="CurrentResults.length > 0" v-for="term in CurrentResults" :card-info="term" />
      <div v-else>
         <p>We couldn't find any matches for the searched term.</p>
        </div>
    </div>
  </div>
  <pagination :number-of-pages="TotalNumberOfPages" v-model:current-page="CurrentPage" />
</template>