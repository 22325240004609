import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container pagi" }
const _hoisted_2 = { class: "pagination" }
const _hoisted_3 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.numberOfPages, (n) => {
        return (_openBlock(), _createElementBlock("li", {
          class: _normalizeClass(["page-item", $setup.ActivePage == n ? 'active' : '']),
          onClick: $event => ($setup.HandlePageChange(n))
        }, _toDisplayString(n), 11 /* TEXT, CLASS, PROPS */, _hoisted_3))
      }), 256 /* UNKEYED_FRAGMENT */))
    ])
  ]))
}