import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "glossary-content" }
const _hoisted_3 = { class: "glossary-title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("a", {
    class: "glossary-card",
    href: $props.cardInfo.DefaultUrl,
    target: "",
    rel: ""
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString($props.cardInfo.Title), 1 /* TEXT */)
    ])
  ], 8 /* PROPS */, _hoisted_1))
}